'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _playable = require('./mixins/playable');

var _playable2 = _interopRequireDefault(_playable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* eslint no-unused-vars:0 */


var assign = require('lodash/assign');
var isEqual = require('lodash/isEqual');

/**
 * The Abstract <Effect/> component represents the behavior of a Grouped set of <Animatable/>
 * components. A sub-type must be provided.
 */

var Effect = function (_Component) {
  _inherits(Effect, _Component);

  function Effect(type) {
    _classCallCheck(this, Effect);

    var _this = _possibleConstructorReturn(this, (Effect.__proto__ || Object.getPrototypeOf(Effect)).call(this));

    _this.state = {
      player: null
    };

    /**
     * The type of groupable component.
     */
    _this.type = type;

    /**
     * Store the DOM nodes of the child <Animatable/>
     * @type {{}}
     */
    _this.nodes = {};

    /**
     * The effect
     * @type {null}
     */
    _this.effect = null;
    return _this;
  }

  _createClass(Effect, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.frameCache = Object.assign({}, this.buildFrameCache(this.props));
      this.keyframeEffects = this.getKeyframeEffectsFromChildren(this.props);
      this.effect = this.getEffectFromKeyframes(this.keyframeEffects);

      // start the animation
      var player = this.startAnimation();
      // But make sure that we honor the initial playState, if set.
      this.updatePlayer(this.props, player);
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var nextKeyframes = this.getKeyframeEffectsFromChildren(nextProps);
      var newFrameCache = Object.assign({}, this.buildFrameCache(nextProps));
      var currentTime = nextProps.currentTime;


      if (!isEqual(newFrameCache, this.frameCache)) {
        this.keyframeEffects = nextKeyframes;
        this.effect = this.getEffectFromKeyframes(nextKeyframes);
        this.startAnimation(nextProps);
      }

      this.updatePlayer(nextProps);
    }
  }, {
    key: 'getKeyframeEffectsFromChildren',
    value: function getKeyframeEffectsFromChildren(props) {
      var _this2 = this;

      var children = props.children;

      return _react.Children.map(children, function (c, idx) {
        return new KeyframeEffect(_this2.nodes[idx], c.props.keyframes, c.props.timing);
      });
    }
  }, {
    key: 'startAnimation',
    value: function startAnimation(props) {
      return this.setPlayer(document.timeline.play(this.effect), props);
    }
  }, {
    key: 'buildFrameCache',
    value: function buildFrameCache(props) {
      var children = props.children;

      var cache = {};
      return _react.Children.forEach(children, function (c, idx) {
        cache[idx] = { frames: c.props.keyframes, timing: c.props.timing };
      });
    }
  }, {
    key: 'getEffectFromKeyframes',
    value: function getEffectFromKeyframes(keyframeEffects) {
      // create the group
      var Type = window[this.type] || window.GroupEffect;
      return new Type(keyframeEffects);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          children = _props.children,
          component = _props.component,
          getRef = _props.getRef;


      var childElements = _react.Children.map(children, function (c, idx) {
        return _react2.default.cloneElement(c, {
          ref: function ref(el) {
            if (el) {
              _this3.nodes[idx] = el.node;
              return el.node;
            }
            return null;
          }
        });
      });

      return _react2.default.createElement(component, _extends({}, this.props, {
        ref: function ref(node) {
          _this3.wrapper = node;
          if (getRef) {
            getRef(node);
          }
          return node;
        }
      }), childElements);
    }
  }]);

  return Effect;
}(_react.Component);

assign(Effect.prototype, _playable2.default);

Effect.defaultProps = {
  component: 'div'
};

Effect.propTypes = {
  onCancel: _propTypes2.default.func,
  onFinish: _propTypes2.default.func,
  onPause: _propTypes2.default.func,
  onPlay: _propTypes2.default.func,
  onReverse: _propTypes2.default.func,
  getRef: _propTypes2.default.func,
  currentTime: _propTypes2.default.number,
  playState: _propTypes2.default.oneOf(['running', 'paused', 'finished', 'idle', 'reversed']),
  component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.element]),
  children: _propTypes2.default.arrayOf(_propTypes2.default.element).isRequired
};

exports.default = Effect;