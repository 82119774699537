'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _with_hoc_elements = require('./utils/with_hoc_elements');

var _with_hoc_elements2 = _interopRequireDefault(_with_hoc_elements);

var _animation = require('./animation');

var _animation2 = _interopRequireDefault(_animation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _with_hoc_elements2.default)(_animation2.default);