'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _dom_elements = require('./dom_elements');

var _dom_elements2 = _interopRequireDefault(_dom_elements);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } // eslint-disable-line no-unused-vars


var createHocForTypes = function createHocForTypes(Target) {
  var validPropTypes = Object.keys(Target.propTypes || {});

  var WrapElement = function WrapElement(tag) {
    var _class, _temp;

    return _temp = _class = function (_Component) {
      _inherits(Wrapped, _Component);

      function Wrapped() {
        _classCallCheck(this, Wrapped);

        return _possibleConstructorReturn(this, (Wrapped.__proto__ || Object.getPrototypeOf(Wrapped)).apply(this, arguments));
      }

      _createClass(Wrapped, [{
        key: 'render',
        value: function render() {
          var _this2 = this;

          var _props = this.props,
              children = _props.children,
              rest = _objectWithoutProperties(_props, ['children']);

          var propsToAnimation = validPropTypes.reduce(function (animationProps, validProp) {
            animationProps[validProp] = rest[validProp];
            return animationProps;
          }, {});

          var propsToChild = Object.keys(rest).reduce(function (childProps, childProp) {
            if (validPropTypes.indexOf(childProp) === -1) {
              childProps[childProp] = rest[childProp];
            }
            return childProps;
          }, {});

          return _react2.default.createElement(
            Target,
            _extends({}, propsToAnimation, {
              ref: function ref(el) {
                if (el && el.node) {
                  // pass node reference
                  _this2.node = el.node;
                }
              }
            }),
            (0, _react.createElement)(tag, propsToChild, children)
          );
        }
      }]);

      return Wrapped;
    }(_react.Component), _class.propTypes = {
      children: _propTypes.node
    }, _temp;
  };

  // Shorthands for all valid HTML Elements
  _dom_elements2.default.forEach(function (domElement) {
    Target[domElement] = WrapElement(domElement);
    Target[domElement].displayName = (Target.name || Target.displayName || 'WrapElement') + '.' + domElement;
  });

  return Target;
};

exports.default = createHocForTypes;