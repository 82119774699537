'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _animatable = require('./animatable');

var _animatable2 = _interopRequireDefault(_animatable);

var _playable = require('./mixins/playable');

var _playable2 = _interopRequireDefault(_playable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var assign = require('lodash/assign');
var isEqual = require('lodash/isEqual');

/**
 * <Animation/> is a simple implementation of <Animatable/> and controls a single
 * "player" instance for the wrapped element.
 */

var Animation = function (_Animatable) {
  _inherits(Animation, _Animatable);

  function Animation() {
    _classCallCheck(this, Animation);

    var _this = _possibleConstructorReturn(this, (Animation.__proto__ || Object.getPrototypeOf(Animation)).call(this));

    _this.state = {
      player: null
    };
    return _this;
  }

  /**
   * Start the animation and set the player in the state
   */


  _createClass(Animation, [{
    key: 'startAnimation',
    value: function startAnimation(props) {
      return this.setPlayer(this.node.animate(this.keyframes, this.timing), props);
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var timing = nextProps.timing,
          keyframes = nextProps.keyframes;

      // create data structures for props

      if (timing && keyframes) {
        var newTiming = Object.assign({}, timing);

        if (!isEqual(keyframes, this.keyframes) || !isEqual(newTiming, this.timing)) {
          this.timing = newTiming;
          this.keyframes = keyframes;
          // start the new animation with the new config
          this.startAnimation(nextProps);
        }
      }

      this.updatePlayer(nextProps);
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          timing = _props.timing,
          keyframes = _props.keyframes;

      // create data structures for props

      this.keyframes = keyframes;
      this.timing = Object.assign({}, timing);

      // start the animation
      var player = this.startAnimation();
      // But make sure that we honor the initial playState, if set.
      this.updatePlayer(this.props, player);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          children = _props2.children,
          getRef = _props2.getRef;


      this.element = _react2.default.cloneElement(children, {
        ref: function ref(node) {
          _this2.node = node;
          if (getRef) {
            getRef(node);
          }
          return node;
        }
      });

      return _react.Children.only(this.element);
    }
  }]);

  return Animation;
}(_animatable2.default);

assign(Animation.prototype, _playable2.default);

Animation.propTypes = assign({}, _animatable2.default.propTypes, {
  onCancel: _propTypes2.default.func,
  onFinish: _propTypes2.default.func,
  onPause: _propTypes2.default.func,
  onPlay: _propTypes2.default.func,
  onReverse: _propTypes2.default.func,
  currentTime: _propTypes2.default.number,
  playState: _propTypes2.default.oneOf(['running', 'paused', 'finished', 'idle', 'reversed'])
});

exports.default = Animation;