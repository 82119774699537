'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _with_hoc_elements = require('./utils/with_hoc_elements');

var _with_hoc_elements2 = _interopRequireDefault(_with_hoc_elements);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * The <Animatable/> module wraps an element and expects props
 * with Keyframe Values and a Timing configuration. However, unlike
 * <Animation/> it doesn't start or maintain any players. <Animatable/> is meant
 * to be nested under other effects like <AnimationGroup/> or <AnimationSequence/>
 */
var Animatable = function (_Component) {
  _inherits(Animatable, _Component);

  function Animatable() {
    _classCallCheck(this, Animatable);

    return _possibleConstructorReturn(this, (Animatable.__proto__ || Object.getPrototypeOf(Animatable)).apply(this, arguments));
  }

  _createClass(Animatable, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      // get the children, and optional function to get a ref to the node
      var _props = this.props,
          children = _props.children,
          getRef = _props.getRef;

      // create our element

      this.element = _react2.default.cloneElement(children, {
        ref: function ref(node) {
          _this2.node = node;
          // if the user supplied a getRef function, pass the ref
          if (getRef) {
            getRef(node);
          }
          return node;
        }
      });

      // render only the child, creating no wrapping elements
      return _react.Children.only(this.element);
    }
  }]);

  return Animatable;
}(_react.Component);

Animatable.propTypes = {
  children: _propTypes2.default.element.isRequired,
  keyframes: _propTypes2.default.arrayOf(Object),
  getRef: _propTypes2.default.func,
  timing: _propTypes2.default.shape({
    delay: _propTypes2.default.number,
    endDelay: _propTypes2.default.number,
    fill: _propTypes2.default.oneOf(['none', 'forwards', 'backwards', 'both', 'auto']),
    iterationStart: _propTypes2.default.number,
    iterations: _propTypes2.default.number,
    duration: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    direction: _propTypes2.default.oneOf(['normal', 'reverse', 'alternate', 'alternate-reverse']),
    easing: _propTypes2.default.string
  }).isRequired
};

exports.default = (0, _with_hoc_elements2.default)(Animatable);